import { Trans, useLingui } from '@lingui/react/macro';
import { TimeClock, useUserDateFormat } from '@sit/client-shared';
import { dateWithFallback, numberWithFallback } from '@web/helpers/label';
import { Checkbox, CheckboxGroup } from 'carbon-react/lib/components/checkbox';
import Typography from 'carbon-react/lib/components/typography';
import styled from 'styled-components';
import { OnFieldChangeFn } from '../../ActiveClock/ClockField';

const FieldLabel = styled(Typography).attrs({ variant: 'strong' })`
  display: block;
  height: ${({ theme }) => theme.spacing * 3}px;
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

const FieldContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing * 3}px;
`;

const FieldValue = styled(Typography).attrs({ mb: 0 })``;

export type HeadFieldsProps = {
  clock: TimeClock;
  readonly: boolean;
  onFieldChange: OnFieldChangeFn;
};

const getClockDuration = (clock: TimeClock) => {
  const clockIn = new Date(clock.clockInTime);
  const clockOut = !clock.clockOutTime ? new Date() : new Date(clock.clockOutTime);

  return {
    clockIn,
    clockOut,
    totalHours: (clockOut.getTime() - clockIn.getTime()) / 1000,
  };
};

function HeadFields({ clock, readonly, onFieldChange }: HeadFieldsProps) {
  const { t } = useLingui();
  const dateFormat = useUserDateFormat();

  const { clockIn, totalHours } = getClockDuration(clock);

  return (
    <>
      <FieldContainer>
        <FieldLabel>
          <Trans>Date</Trans>
        </FieldLabel>
        <FieldValue>{dateWithFallback(clockIn, dateFormat)}</FieldValue>
      </FieldContainer>
      <FieldContainer>
        <FieldLabel>
          <Trans>Total hours</Trans>
        </FieldLabel>
        <FieldValue>{numberWithFallback(totalHours)}</FieldValue>
      </FieldContainer>
      <FieldContainer>
        <CheckboxGroup legend={t`Billable`}>
          <Checkbox checked={clock.billable} disabled={readonly} onChange={({ target }) => onFieldChange({ billable: target.checked })} />
        </CheckboxGroup>
      </FieldContainer>
    </>
  );
}

export default HeadFields;
