import { AppLocale } from '@sit/core';
import enUS from 'date-fns/locale/en-US';
import frCA from 'date-fns/locale/fr-CA';

export function getDateFnsLocale(locale: AppLocale) {
  // The French locale adds 6KB without gzip to the bundle
  if (locale === 'fr-CA') {
    return frCA;
  }
  return enUS;
}
