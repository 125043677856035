import { createContext } from 'react';
import { TimesheetModalType } from './timesheet-modal-type';

export type TimesheetModalState =
  | {
      type: TimesheetModalType.NEW | TimesheetModalType.CLOSED;
    }
  | {
      type: TimesheetModalType.DUPLICATE;
      timesheetId: string;
    }
  | {
      type: TimesheetModalType.BULK_DELETE;
      timesheetIds: string[];
    };

export const TimesheetModalsContext = createContext<{
  state: TimesheetModalState;
  openNewTimesheetModal: () => void;
  openDuplicateTimesheetModal: (timesheetId: string) => void;
  openBulkDeleteTimesheetsModal: (timesheetIds: string[]) => void;
  closeModal: () => void;
}>({
  state: { type: TimesheetModalType.CLOSED },
  openNewTimesheetModal: () => {},
  openDuplicateTimesheetModal: () => {},
  openBulkDeleteTimesheetsModal: () => {},
  closeModal: () => {},
});
