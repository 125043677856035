import { usePostNotificationsReadAll } from '@sit/client-shared';
import { useCallback, useContext } from 'react';
import { NotificationHandlerContext } from '../NotificationHandlerContext';

export function useSetNotificationsAsRead() {
  const { markAsRead } = useContext(NotificationHandlerContext);
  const { mutate: markAsReadMutation } = usePostNotificationsReadAll();
  const setAsRead = useCallback(() => {
    markAsRead();
    markAsReadMutation({});
  }, [markAsRead, markAsReadMutation]);
  return setAsRead;
}
