import { CompanyType, GetApiCompanyError, useGetCompany } from '@sit/client-shared';
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useCurrentEntity } from '@web/hooks/use-current-entity';
import { useIsAuthenticated } from '@web/redux/hooks';

export function useGetCurrentCompany<TData = CompanyType>(
  options?: Omit<UseQueryOptions<CompanyType, GetApiCompanyError, TData, QueryKey>, 'queryKey' | 'queryFn' | 'initialData'>,
) {
  const isAuthenticated = useIsAuthenticated();
  const companyId = useCurrentEntity()?.id;

  return useGetCompany<TData>(
    {
      queryParams: {
        companyId,
      },
    },
    { ...options, enabled: options?.enabled !== false && isAuthenticated && !!companyId },
  );
}
