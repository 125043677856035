import { Trans, useLingui } from '@lingui/react/macro';
import { formatLabelNumber } from '@web/helpers/time';
import { ButtonToggle, ButtonToggleGroup } from 'carbon-react/lib/components/button-toggle';
import Typography from 'carbon-react/lib/components/typography';
import addHours from 'date-fns/addHours';
import addMilliseconds from 'date-fns/addMilliseconds';
import addMinutes from 'date-fns/addMinutes';
import addSeconds from 'date-fns/addSeconds';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import clamp from 'lodash/clamp';
import { useState } from 'react';
import styled from 'styled-components';
import Textbox, { FieldValidationHandler } from '../Textbox/DebouncedTextbox';

const Root = styled.div``;

const FieldRoot = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing * 2}px;
`;

const TimeInputRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr ${({ theme }) => theme.spacing}px 1fr;
  grid-template-rows: 1fr;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing / 2}px;

  & label {
    font-weight: ${({ theme }) => theme.compatibility.fontWeights400};
  }

  & [role="presentation"] {
    flex-wrap: nowrap;
  }
`;

const ControlGroupContainer = styled.div`
  & [data-component="button-toggle-group"] {
    flex-wrap: nowrap;
  }
`;

interface TimeValue {
  baseDate: Date;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
  period?: 'AM' | 'PM';
}

const toTimeValue = (date: Date): TimeValue => {
  const [hours, minutes, period, seconds, milliseconds] = format(date, 'hh,mm,aa,ss,SSS').split(',') as [
    string,
    string,
    'AM' | 'PM',
    string,
    string,
  ];

  return {
    baseDate: startOfDay(date),
    hours: Number(hours),
    minutes: Number(minutes),
    seconds: Number(seconds),
    milliseconds: Number(milliseconds),
    period,
  };
};

const fromTimeValue = ({ baseDate, hours, minutes, period, seconds, milliseconds }: TimeValue): Date => {
  const normalizedHours = hours % 12;

  const hours24 = period === 'PM' ? normalizedHours + 12 : normalizedHours;

  let newDate = addHours(baseDate, hours24);
  newDate = addMinutes(newDate, minutes);
  newDate = addSeconds(newDate, seconds);

  return addMilliseconds(newDate, milliseconds);
};

const TIME_RANGES: Record<'hours' | 'minutes', [number, number]> = {
  hours: [1, 12],
  minutes: [0, 59],
};

export interface TimeInputProps {
  label?: string;
  disabled?: boolean;
  value: Date;
  onChange: (value: Date) => void;
  error?: boolean | string;
}

function useTimeInputControls(value: Date, onChange: (value: Date) => void) {
  const { t } = useLingui();
  const [lastInteractedField, setLastInteractedField] = useState<'hours' | 'minutes'>('hours');

  const timeValue = toTimeValue(value);

  const handleGroupChange = (ev: React.MouseEvent<HTMLButtonElement>, value?: string) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (!value) {
      return;
    }

    setLastInteractedField('hours');

    onChange(
      fromTimeValue({
        ...timeValue,
        period: value as 'AM' | 'PM',
      }),
    );
  };

  const validateTimeValue =
    (field: 'hours' | 'minutes'): FieldValidationHandler =>
    (value) => {
      const number = Number(value);

      if (Number.isNaN(number) || !value.trim()) {
        return t`Invalid number`;
      }

      if (number < TIME_RANGES[field][0] || number > TIME_RANGES[field][1]) {
        return t`Please enter a number between ${TIME_RANGES[field][0]} and ${TIME_RANGES[field][1]}`;
      }

      return;
    };

  const handleChangeField = (field: 'hours' | 'minutes') => (value: string) => {
    const number = Number(value);

    if (Number.isNaN(number)) {
      return;
    }

    const normalizedValue = clamp(number, TIME_RANGES[field][0], TIME_RANGES[field][1]);

    setLastInteractedField(field);

    return onChange(
      fromTimeValue({
        ...timeValue,
        [field]: normalizedValue,
      }),
    );
  };

  return {
    timeValue,
    handleGroupChange,
    validateTimeValue,
    handleChangeField,
    lastInteractedField,
  };
}

function TimeInput({ onChange, value, label, disabled, error }: TimeInputProps) {
  const { t } = useLingui();

  const { timeValue, handleGroupChange, validateTimeValue, handleChangeField, lastInteractedField } = useTimeInputControls(value, onChange);

  return (
    <Root>
      {label && <Typography variant="strong">{label}</Typography>}
      <FieldRoot>
        <TimeInputRoot>
          <Textbox
            disabled={disabled}
            label={t`Hrs.`}
            defaultValue={`${formatLabelNumber(timeValue.hours)}`}
            onValueChangeDebounced={handleChangeField('hours')}
            error={lastInteractedField === 'hours' ? error : undefined}
            validate={validateTimeValue('hours')}
          />
          <Typography textAlign="center" mt={4} mb={0}>
            :
          </Typography>
          <Textbox
            disabled={disabled}
            label={t`Mins.`}
            defaultValue={`${formatLabelNumber(timeValue.minutes)}`}
            onValueChangeDebounced={handleChangeField('minutes')}
            error={lastInteractedField === 'minutes' ? error : undefined}
            validate={validateTimeValue('minutes')}
          />
        </TimeInputRoot>
        <ControlGroupContainer>
          <ButtonToggleGroup value={timeValue.period} onChange={handleGroupChange} disabled={disabled} id="DayPeriod">
            <ButtonToggle value="AM">
              <Trans>AM</Trans>
            </ButtonToggle>
            <ButtonToggle value="PM">
              <Trans>PM</Trans>
            </ButtonToggle>
          </ButtonToggleGroup>
        </ControlGroupContainer>
      </FieldRoot>
    </Root>
  );
}

export default TimeInput;
