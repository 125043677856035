import { useLingui } from '@lingui/react/macro';
import DateInput from '@web/components/Shared/DateInput';
import TimeInput from '@web/components/Shared/TimeInput';
import Box from 'carbon-react/lib/components/box';
import { DateChangeEvent } from 'carbon-react/lib/components/date';
import Icon from 'carbon-react/lib/components/icon';
import Tooltip from 'carbon-react/lib/components/tooltip';
import Typography from 'carbon-react/lib/components/typography';
import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const ErroredRootStyle = css`
  &::before {
    content: ' ';
    position: absolute;
    top: ${({ theme }) => theme.spacing * -1.5}px;
    right: ${({ theme }) => theme.spacing * -1.5}px;
    bottom: 0;
    left: ${({ theme }) => theme.spacing * -1.5}px;
    border-radius: ${({ theme }) => theme.spacing * 0.5}px;
    border: 1px solid ${({ theme }) => theme.colors.error};
    box-shadow: inset 1px 1px 0 ${({ theme }) => theme.colors.error}, inset -1px -1px 0 ${({ theme }) => theme.colors.error};
  }
`;

const Root = styled.div<{ error?: boolean }>`
  position: relative;
  max-width: fit-content;
  ${({ error }) => error && ErroredRootStyle}
`;

const DateFieldContainer = styled.div`
  & label {
    font-weight: ${({ theme }) => theme.compatibility.fontWeights400};
  }
`;

const FieldRoot = styled.div`
`;

export interface DateTimeInputProps {
  label?: string | React.ReactNode;
  value: Date;
  disabled?: boolean;
  onChange: (value: Date) => void;
  error?: boolean | string;
}

function DateTimeInput({ onChange, value, label, error, disabled }: DateTimeInputProps) {
  const { t } = useLingui();
  const [lastInteractedField, setLastInteractedField] = useState<'date' | 'time' | undefined>();

  const handleOnChange = (newValue: Date, from: 'date' | 'time' = 'time') => {
    setLastInteractedField(from);
    onChange(newValue);
  };

  const handleChangeDate = ({ target }: DateChangeEvent) => {
    const { rawValue } = target.value;

    const valueString = value.toISOString();
    const currentDate = valueString.substring(0, 10);

    if (!rawValue || rawValue === currentDate) {
      return;
    }

    const valueTime = valueString.substring(10);
    const newValue = parseISO(`${rawValue}${valueTime}`);

    handleOnChange(newValue, 'date');
  };

  const visibleLabel = typeof label === 'string' ? <Typography variant="strong">{label}</Typography> : label;

  return (
    <Root error={lastInteractedField === undefined && !!error}>
      {visibleLabel}
      {error && lastInteractedField === undefined && (
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
          <Tooltip message={error} type="error" position="right">
            <Icon type="error" color="error" />
          </Tooltip>
        </Box>
      )}
      <DateFieldContainer>
        <DateInput
          disabled={disabled}
          label={t`Date`}
          maxWidth="100%"
          value={format(value, 'yyyy-MM-dd')}
          onChange={handleChangeDate}
          error={lastInteractedField === 'date' ? error : undefined}
        />
      </DateFieldContainer>
      <FieldRoot>
        <TimeInput error={lastInteractedField === 'time' ? error : undefined} disabled={disabled} value={value} onChange={handleOnChange} />
      </FieldRoot>
    </Root>
  );
}

export default DateTimeInput;
