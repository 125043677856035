import { Plural, Trans } from '@lingui/react/macro';
import { TimesheetType } from '@sit/client-shared';
import Button from '@web/components/Shared/Button';
import Dialog from '@web/components/Shared/Dialog';
import Form from 'carbon-react/lib/components/form';
import Typography from 'carbon-react/lib/components/typography';
import { useCallback } from 'react';
import styled from 'styled-components';
import { TimesheetsList } from './TimesheetsList';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};
`;

interface DeleteTimesheetsModalProps {
  ids: string[] | undefined;
  timesheets: Pick<TimesheetType, 'startDate' | 'endDate' | 'id' | 'description'>[];
  onClose: () => void;
  onOk: () => void;
  open: boolean;
}

export const DeleteTimesheetsModal = ({ ids, onClose, onOk, timesheets, open }: DeleteTimesheetsModalProps) => {
  const data = ids ? timesheets.filter((t) => ids.includes(t.id)) : [];

  const handleConfirm = useCallback(() => {
    onOk();
    onClose();
  }, [onOk, onClose]);

  const timesheetCount = ids?.length ?? 0;

  return (
    <Dialog
      disableAutoFocus
      disableEscKey={false}
      enableBackgroundUI={false}
      onCancel={onClose}
      open={open}
      showCloseIcon
      title={
        <Typography variant="h1" pb={2}>
          <Plural value={timesheetCount} one="Delete timesheet" other="Delete # timesheets" />
        </Typography>
      }
      data-cy="delete-timesheet-modal"
      complexForm
    >
      <Form
        stickyFooter
        saveButton={
          <Button
            buttonType={{
              default: 'tertiary',
              embedded: 'secondary',
            }}
            destructive
            onClick={handleConfirm}
            ml={1}
          >
            <Trans>Delete</Trans>
          </Button>
        }
        leftSideButtons={
          <Button
            buttonType={{
              default: 'tertiary',
              embedded: 'secondary',
            }}
            onClick={onClose}
            data-cy="create-timesheet-cancel-button"
          >
            <Trans>Cancel</Trans>
          </Button>
        }
        fieldSpacing={0}
      >
        <Content>
          <Typography display="block">
            <Plural
              value={timesheetCount}
              one="The following timesheet will be permanently deleted."
              other="The following timesheets will be permanently deleted."
              _0="You selected zero timesheets."
            />
          </Typography>
          <TimesheetsList timesheets={data} />
        </Content>
      </Form>
    </Dialog>
  );
};
