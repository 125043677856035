import { DimValueWithObjectName, PostApiTimeClocksRequestBody, TimeClock, TimeClockBreak, UserType } from '@sit/client-shared';
import { UpdateClockPayload } from '@web/components/TimeClock/ActiveClock/ClockField';
import { v4 as randomUUID } from 'uuid';

export const cloneAndClockInHelper = (clock: TimeClock): TimeClock => {
  const newId = randomUUID();
  const newClockedIn = new Date().toISOString();

  return { ...clock, id: newId, clockInTime: newClockedIn, clockOutTime: null };
};

export const cloneAndClockOutHelper = (clock: TimeClock): TimeClock => {
  const newClockedOut = new Date().toISOString();

  return { ...clock, clockOutTime: newClockedOut };
};

const FALLBACK_TIME_CLOCK: Omit<TimeClock, 'companyId' | 'id' | 'employee'> = {
  clockInTime: new Date().toISOString(),
  clockOutTime: new Date().toISOString(),
  billable: false,
  description: null,
  breaks: [],
  client: undefined,
  item: undefined,
  notes: null,
  project: undefined,
  task: undefined,
  timeType: undefined,
  timesheetId: null,
  readonly: false,
};

export const computeDisableDimensions = (user: UserType): string[] => {
  return user.autofillValues
    .filter(({ allowOverride }) => !allowOverride)
    .map(({ dimensionObjectName }) => dimensionObjectName) as string[];
};

type ComputableUser = Omit<UserType, 'employee'> & { employee: { id: string; name: string } };

export const computeFallbackTimeclock = (companyId: string, user: ComputableUser): TimeClock => {
  const dimensionsValues = user.autofillValues?.reduce<DimValueWithObjectName[]>((list, autofill) => {
    if (!autofill.dimensionObjectName || !autofill.valueExternalId) {
      return list;
    }

    list.push({
      externalId: autofill.valueExternalId,
      id: autofill.valueId,
      name: autofill.valueName,
      dimensionObjectName: autofill.dimensionObjectName,
    });

    return list;
  }, []);

  return {
    id: randomUUID(),
    employee: user.employee,
    companyId,
    dimensionsValues,
    ...FALLBACK_TIME_CLOCK,
  };
};

export const getPostTimeClockRequestBody = (clock: TimeClock): PostApiTimeClocksRequestBody => {
  const dimensionValues = clock.dimensionsValues?.map(({ id }) => id);

  return {
    billable: clock.billable,
    clientId: clock.client?.id,
    clockInTime: clock.clockInTime,
    clockOutTime: clock.clockOutTime ?? undefined,
    description: clock.description,
    dimensionValues,
    id: clock.id,
    itemId: clock.item?.id,
    notes: clock.notes,
    projectId: clock.project?.id,
    taskId: clock.task?.id,
    timeTypeId: clock.timeType?.id,
    ...(clock.employee?.id ? { employeeId: clock.employee.id } : {}),
  };
};

export const computeFallbackTimeclockBreak = (): TimeClockBreak => ({
  id: randomUUID(),
  startTime: new Date().toISOString(),
  endTime: null,
});

export const toUpdateClockPayload = (clock: TimeClock | undefined): UpdateClockPayload | undefined => {
  if (!clock) {
    return undefined;
  }

  return {
    billable: clock.billable,
    clientId: clock.client?.id,
    clockInTime: clock.clockInTime,
    clockOutTime: clock.clockOutTime,
    description: clock.description,
    itemId: clock.item?.id,
    notes: clock.notes,
    projectId: clock.project?.id,
    taskId: clock.task?.id,
    timeTypeId: clock.timeType?.id,
  };
};
