import { useLingui } from '@lingui/react/macro';
import { getStringFromDate } from '@sit/core';
import { useGetCurrentCompany } from '@web/api/company/use-get-company';
import { usePostTimesheet } from '@web/api/timesheet/use-post-timesheet';
import { useGetUser } from '@web/api/users/use-get-user';
import { Navigate } from 'react-router-dom';
import { useLocationQuery } from '../../../helpers/hooks';
import toastr from '../../../helpers/toastr';
import { getStaffTimesheetsRoute, getTimesheetsRoute } from '../helpers/routes';
import { getEndDateString, getMonthRemainingHalfString, getStartOfWeekString } from '../helpers/timesheets';
import { useIsStaff, useTimesheetModals } from '../hooks';
import { CreateTimesheetModal, type FormData } from './CreateTimesheetModal';
import { mustHaveCreateStaffPermissions, mustHaveEditStaffPermissions, mustHaveEmployee, noEditPermissions } from './messages';

const defaultData = {
  weekStart: 1,
  timesheetDuration: 7,
} as const;

const useDefaultData = (query: URLSearchParams) => {
  const { data: company } = useGetCurrentCompany();
  const { weekStart, timesheetDuration } = company || defaultData;
  const today = getStringFromDate(new Date(), { ISO8601DateExtended: true });

  const queryStartDate = query.get('start_date');
  const queryEndDate = query.get('end_date');

  if (queryStartDate && queryEndDate) {
    return {
      startDate: queryStartDate,
      endDate: queryEndDate,
    };
  }

  if (timesheetDuration === 15) {
    return getMonthRemainingHalfString();
  }
  const startDate = timesheetDuration === 1 ? today : getStartOfWeekString(weekStart);

  return {
    startDate,
    endDate: getEndDateString(timesheetDuration, startDate),
  };
};

interface NewTimesheetProps {
  open: boolean;
}

export function NewTimesheet({ open }: NewTimesheetProps) {
  const { i18n } = useLingui();
  const query = useLocationQuery();
  const { startDate, endDate } = useDefaultData(query);
  const { data: userData } = useGetUser();
  const externalEmployeeId = userData?.employee?.employeeId;
  const { closeModal } = useTimesheetModals();
  const staff = useIsStaff();

  const { mutate: createTimesheet } = usePostTimesheet();

  const handleSubmit = ({ startDate, endDate, description, employeeId }: FormData) => {
    if (!employeeId) {
      toastr.warning(i18n._(mustHaveEmployee));
      return;
    }
    createTimesheet({
      body: {
        description,
        endDate: endDate,
        startDate: startDate,
        externalEmployeeId: employeeId,
      },
      queryParams: {
        staff: staff ? 'true' : 'false',
      },
    });
  };

  if (open && !staff && userData && (!userData.canEditTimesheets || !userData.canCreateTimesheet)) {
    const messageDescriptor = !userData.canEditTimesheets ? noEditPermissions : mustHaveEmployee;
    toastr.warning(i18n._(messageDescriptor));
    return <Navigate to={getTimesheetsRoute} />;
  }
  if (open && staff && userData && (!userData.canEditStaffTimesheets || !userData.canCreateStaffTimesheets)) {
    const messageDescriptor = !userData.canEditStaffTimesheets ? mustHaveEditStaffPermissions : mustHaveCreateStaffPermissions;
    toastr.warning(i18n._(messageDescriptor));
    return <Navigate to={getStaffTimesheetsRoute} />;
  }
  return (
    <CreateTimesheetModal
      staff={staff}
      data={{
        startDate,
        endDate,
        description: '',
        employeeId: externalEmployeeId ?? undefined,
      }}
      onSubmit={handleSubmit}
      onClose={closeModal}
      open={open}
    />
  );
}
