import { useLingui } from '@lingui/react/macro';
import { formatIntacctDate, useUserDateFormat } from '@sit/client-shared';
import { getDateFromString } from '@sit/core';
import CarbonDateInput, { DateInputProps as CarbonDateInputProps, DateChangeEvent } from 'carbon-react/lib/components/date';
import { isValid } from 'date-fns';
import { useRef } from 'react';

export interface DateInputProps extends Omit<CarbonDateInputProps, 'onChange'> {
  onChange?: CarbonDateInputProps['onChange'];
}

export default function DateInput({ onChange, value, error, ...props }: DateInputProps) {
  const { t } = useLingui();
  const isDateValid = isValid(getDateFromString(value));

  const dateFormat = useUserDateFormat();
  const invalidDataRef = useRef<string | null>(null);

  const handleOnChange = (ev: DateChangeEvent) => {
    const { rawValue, formattedValue } = ev.target.value;

    const isInvalid = formattedValue.length < dateFormat.length || !rawValue;

    if (isInvalid) {
      invalidDataRef.current = formattedValue;
    } else {
      invalidDataRef.current = null;
    }

    onChange?.(ev);
  };

  const effectiveValue = invalidDataRef.current ?? value;
  const formattedValue =
    effectiveValue.length >= dateFormat.length && isValid(getDateFromString(effectiveValue))
      ? formatIntacctDate(effectiveValue, dateFormat)
      : effectiveValue;
  const errorMessage = effectiveValue !== '' && !isDateValid ? t`Invalid date` : error;

  return (
    <CarbonDateInput
      {...props}
      onChange={handleOnChange}
      value={effectiveValue}
      error={errorMessage}
      // @ts-ignore -- this field is not allowed and should be reviewed, but it might be a carbon-react issue
      formattedValue={formattedValue}
    />
  );
}
