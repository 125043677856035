import { Messages } from '@lingui/core';
import { useLingui } from '@lingui/react/macro';
import { AppLocale } from '@sit/core';
import { useCallback, useEffect } from 'react';
import { messages as enUS } from '../lingui/en-US.po';
import { useUserLocale } from './locale';

const localeImports = {
  'en-US': () => Promise.resolve({ messages: enUS }),
  'en-US-pseudo': () => import('../lingui/en-US-pseudo.po'),
  'fr-CA': () => import('../lingui/fr-CA.po'),
} as const satisfies Record<AppLocale, () => Promise<{ messages: Messages }>>;

function useLoadAndActivateLocale() {
  const { i18n } = useLingui();
  return useCallback(
    async (appLocale: AppLocale) => {
      const { messages } = await localeImports[appLocale]();
      i18n.loadAndActivate({ locale: appLocale, messages });
    },
    [i18n],
  );
}

export function useActivateUserLocale() {
  const locale = useUserLocale();
  const loadAndActivateLocale = useLoadAndActivateLocale();
  useEffect(() => {
    void loadAndActivateLocale(locale).catch();
  }, [locale, loadAndActivateLocale]);
  return locale;
}
