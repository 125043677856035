import {
  AutofillValue,
  GetApiEmployeesResponse,
  getEmployeesQueryData,
  PostApiTimesheetsByUuidRowsError,
  PostApiTimesheetsByUuidRowsVariables,
  setTimesheetRowQueryData,
  TimesheetRow,
  usePostTimesheetRow as usePostApiTimesheetRow,
} from '@sit/client-shared';
import { MutateOptions, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { setAutofill } from '@web/redux/Autofill/autofill-slice';
import { useAppDispatch } from '@web/redux/store';
import { setSyncStatus } from '@web/redux/timesheets-slice';
import { useCallback } from 'react';
import { v4 } from 'uuid';
import { useGetUser } from '../users/use-get-user';

function useHandleAutofillValues() {
  const dispatch = useAppDispatch();
  return ({
    autofillValues,
    rowId,
  }: {
    autofillValues: AutofillValue[];
    rowId: string;
  }) => Promise.all(autofillValues.map((a) => dispatch(setAutofill({ rowId, a, from: 'user' }))));
}

interface PostTimesheetPayload {
  timesheetUserId?: string;
  employeeId?: string;
}

export function usePostTimesheetRow(
  options?:
    | Omit<UseMutationOptions<TimesheetRow, PostApiTimesheetsByUuidRowsError, PostApiTimesheetsByUuidRowsVariables, unknown>, 'mutationFn'>
    | undefined,
) {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { data: userData } = useGetUser();
  const handleAutofillValues = useHandleAutofillValues();
  const {
    mutate,
    mutateAsync: _mutateAsync,
    ...rest
  } = usePostApiTimesheetRow({
    ...options,
  });
  const createTimesheetRow = useCallback(
    (
      payload: PostTimesheetPayload,
      variables: PostApiTimesheetsByUuidRowsVariables,
      options?: MutateOptions<TimesheetRow, PostApiTimesheetsByUuidRowsError, PostApiTimesheetsByUuidRowsVariables, unknown> | undefined,
    ) => {
      const rowId = variables.body?.id ?? v4();
      const timesheetId = variables.pathParams.id;
      const optimisticVariables = {
        ...variables,
        body: {
          // This converts the call to an optimistic update
          ...variables.body,
          id: rowId,
        },
      } satisfies PostApiTimesheetsByUuidRowsVariables;

      setTimesheetRowQueryData(queryClient, timesheetId, rowId, () => {
        const row: TimesheetRow = {
          created_at: new Date().toISOString(),
          // @ts-expect-error -- will get overwritten by the API response
          dimensionValues: {},
          id: rowId,
          entries: [],
          negativeHours: variables.body?.negativeHours ?? false,
          source: 'controller',
          timesheet_id: timesheetId,
          updated_at: new Date().toISOString(),
        };
        return row;
      });

      let userAutofillValues: AutofillValue[] | undefined = [];
      const userIdOfTimesheet = payload.timesheetUserId;
      if (userData?.id === userIdOfTimesheet) {
        userAutofillValues = userData?.autofillValues;
      } else {
        // this is a staff timesheet, need to get the autofill values from the timesheet user
        const employeesData = getEmployeesQueryData(queryClient);
        let timesheetUser: GetApiEmployeesResponse['employees'][number] | undefined;
        for (const [queryKey, queryData] of employeesData) {
          // ['employees']
          if (queryKey.length === 1) {
            timesheetUser = queryData?.employees.find((u) => u.employeeId === payload.employeeId);
            if (timesheetUser) break;
          }
        }
        userAutofillValues = timesheetUser?.autofillValues;
      }
      if (userAutofillValues && userAutofillValues.length > 0) {
        handleAutofillValues({ autofillValues: userAutofillValues, rowId });
      }
      dispatch(setSyncStatus({ timesheetId, synced: false }));

      return mutate(optimisticVariables, options);
    },
    [mutate, handleAutofillValues, queryClient, userData?.id, userData?.autofillValues],
  );
  return { createTimesheetRow, ...rest };
}
