/**
 * @returns an unvalidated, unverified token
 */
export function parseToken(token: string) {
  const b64 = token.split('.')[1];
  if (!b64) throw new Error('Invalid token');
  return JSON.parse(atob(b64)) as {
    aud: 'sit';
    exp: number;
    iat: number;
    id: string;
    jti: string;
  };
}
