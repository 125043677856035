import { CompanyType } from '@sit/client-shared';
import { getDate, getStringFromDate } from '@sit/core';
import { add, endOfDay, endOfMonth, isAfter, startOfMonth, startOfWeek } from 'date-fns';
import moment, { Moment } from 'moment';

// Helper types
type DateType = Date | string;

interface MonthRemainingHalf<DT extends DateType = Date> {
  startDate: DT;
  endDate: DT;
}

// Utility functions
const parseReturnValue = <DT extends DateType = Date>(input: DT, output: Date): DT => {
  if (typeof input === 'string') {
    return getStringFromDate(output, { ISO8601DateExtended: true }) as DT;
  }
  return output as DT;
};

// getMonthRemainingHalf
export function getMonthRemainingHalf<DT extends DateType = Date>(date: DT): MonthRemainingHalf<DT> {
  const rawDate: DT = date ?? new Date();
  const value = date ? getDate(date) : new Date();
  const today = value.getDate();

  const monthStart = startOfMonth(value);
  const monthEnd = endOfMonth(value);

  if (today <= 15) {
    return {
      startDate: parseReturnValue(rawDate, monthStart),
      endDate: parseReturnValue(rawDate, endOfDay(add(monthStart, { days: 14 }))),
    };
  }

  return {
    startDate: parseReturnValue(rawDate, add(monthStart, { days: 15 })),
    endDate: parseReturnValue(rawDate, monthEnd),
  };
}

export const getMonthRemainingHalfString = (date: string = getStringFromDate(new Date())): MonthRemainingHalf<string> =>
  getMonthRemainingHalf(date);
export const getMonthRemainingHalfDate = (date: Date = new Date()): MonthRemainingHalf<Date> => getMonthRemainingHalf(date);

// getStartOfWeek
export function getStartOfWeek<DT extends DateType = Date>(weekStart: number, date: DT): DT {
  const value = date ? getDate(date) : new Date();
  const today = endOfDay(new Date());

  const result = startOfWeek(value, { weekStartsOn: (weekStart - 1) as 0 | 1 | 2 | 3 | 4 | 5 | 6 });

  if (isAfter(result, today)) {
    return parseReturnValue(date, add(result, { days: -7 }));
  }

  return parseReturnValue(date, result);
}

export const getStartOfWeekString = (weekStart: number, date: string = getStringFromDate(new Date())): string =>
  getStartOfWeek(weekStart, date);
export const getStartOfWeekDate = (weekStart: number, date: Date = new Date()): Date => getStartOfWeek(weekStart, date);

// getEndDate
function getEndDate<DT extends DateType = Date>(length: number, date: DT): DT {
  const value = date ? getDate(date) : new Date();
  return parseReturnValue(date, endOfDay(add(value, { days: length - 1 })));
}

export const getEndDateString = (length: number, date: string = getStringFromDate(new Date())): string => getEndDate(length, date);
export const getEndDateDate = (length: number, date: Date = new Date()): Date => getEndDate(length, date);

// getTimesheetEndDate
function getTimesheetEndDate<DT extends DateType = Date>(company: Pick<CompanyType, 'timesheetDuration'>, startDate: DT): DT {
  const { timesheetDuration } = company;

  if (timesheetDuration === 15) {
    const { endDate } = getMonthRemainingHalf(startDate);

    return endDate;
  }

  return getEndDate(timesheetDuration, startDate);
}

export const getTimesheetEndDateString = (
  company: Pick<CompanyType, 'timesheetDuration'>,
  startDate: string = getStringFromDate(new Date()),
): string => getTimesheetEndDate(company, startDate);

export const getTimesheetEndDateDate = (company: Pick<CompanyType, 'timesheetDuration'>, startDate: Date = new Date()): Date =>
  getTimesheetEndDate(company, startDate);

/**
 * LEGACY FUNCTIONS
 */

/**
 * @deprecated
 */
interface LegacyMonthRemainingHalf {
  startDate: Moment;
  endDate: Moment;
}

/**
 * @deprecated
 * Get the month half remaining from the current date using Moment's classes.
 * @param date - The date to use as the reference.
 * @returns MonthRemainingHalf - The start and end dates of the month half remaining.
 */
export const getLegacyMonthRemainingHalf = (date: Moment = moment()): LegacyMonthRemainingHalf => {
  const todayDate = date.clone();
  const today = todayDate.date();

  const monthStart = todayDate.startOf('month').clone();
  const monthEnd = todayDate.endOf('month').clone();

  if (today <= 15) {
    return {
      startDate: monthStart.clone(),
      endDate: monthStart.clone().add(14, 'days'),
    };
  }

  return {
    startDate: monthStart.clone().add(15, 'days'),
    endDate: monthEnd.clone(),
  };
};

/**
 * @deprecated
 * Get the start of the week using Moment's classes.
 */
export const getLegacyStartOfWeek = (weekStart: number, todayDate: Moment = moment()): Moment => {
  const result = todayDate
    .clone()
    .startOf('week')
    .day(weekStart - 1);
  if (result.isAfter(todayDate)) {
    result.subtract(1, 'week');
  }
  return result;
};

/**
 * @deprecated
 * Get the end of given length using Moment's classes.
 */
export const getLegacyEndDate = (length: number, todayDate: Moment = moment()): Moment => {
  return todayDate.clone().add(length - 1, 'days');
};

/**
 * @deprecated
 * Get the timesheet end date using Moment's classes.
 */
export const getLegacyTimesheetEndDate = (company: Pick<CompanyType, 'timesheetDuration'>, startDate: Moment = moment()): Moment => {
  const { timesheetDuration } = company;

  if (timesheetDuration === 15) {
    const { endDate } = getLegacyMonthRemainingHalf(startDate);

    return endDate;
  }

  return getLegacyEndDate(timesheetDuration, startDate);
};
