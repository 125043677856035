import { useContext } from 'react';
import { NotificationData } from '../notification-data';
import { NotificationHandlerContext } from '../NotificationHandlerContext';

const useNotifications = (): NotificationData[] => {
  const { notifications } = useContext(NotificationHandlerContext);

  return notifications;
};

export default useNotifications;
