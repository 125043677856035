import { persister } from '@web/query-client';
import { parseToken } from './token';

export async function invalidateIdb(token: string | null) {
  if (!token) return;
  const client = await persister.restoreClient();
  const userQuery = client?.clientState?.queries.find((q) => q.queryKey.length === 1 && q.queryKey[0] === 'users');
  if (userQuery?.state.data && typeof userQuery.state.data === 'object' && 'id' in userQuery.state.data) {
    try {
      // Base64 decode the user ID in the token
      const obj = parseToken(token);
      if (obj.id && obj.id === userQuery.state.data.id) {
        // The user in the token is the same as the user in the cache
        // We do not need to invalidate the cache (early `return`).
        return;
      }
    } catch {
      // Fall through to invalidate the cache
    }
  }
  // Invalidate the cache if the user in the token != the current cache's user
  await persister.removeClient();
}
