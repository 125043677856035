import { useLingui } from '@lingui/react/macro';
import { Helmet } from 'react-helmet';
import DashboardContainer from '../DashboardContainer';
import Timesheets from './Timesheets';
import { TimesheetModalsProvider } from './components/TimesheetModalsProvider';
import { TimesheetModals } from './components/TimesheetModals';

const TimesheetsContainer = () => {
  const { t } = useLingui();
  return (
    <DashboardContainer showSecondaryNav>
      <Helmet>
        <title>{t`Timesheets | Sage Intelligent Time`}</title>
      </Helmet>
      <TimesheetModalsProvider>
        <>
          <TimesheetModals />
          <Timesheets />
        </>
      </TimesheetModalsProvider>
    </DashboardContainer>
  );
};

export default TimesheetsContainer;
