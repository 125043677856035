import { Trans, useLingui } from '@lingui/react/macro';
import { loadingChunkRetry, useHasStaffTimesheets } from '@sit/client-shared';
import ErrorBoundary from '@web/components/ErrorBoundary';
import { PageContent, PageHeader } from '@web/components/Page';
import Button from '@web/components/Shared/Button';
import DashboardContainer from '@web/containers/DashboardContainer';
import { NotFoundMessage } from '@web/containers/NotFoundPage/NotFoundMessage';
import { ErrorComponent } from '@web/containers/TimeApprovals/Error';
import CreateTimeClock from '@web/containers/TimeClocks/CreateTimeClock';
import { newStaffTimeClocksRoute } from '@web/containers/TimeClocks/helpers/routes';
import { useClockInOutEnabled } from '@web/hooks/use-clock-in-out';
import { TimesheetModals } from '@web/containers/Timesheets/components/TimesheetModals';
import { TimesheetModalsProvider } from '@web/containers/Timesheets/components/TimesheetModalsProvider';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StaffTimeClocksPage = lazy(() => loadingChunkRetry(() => import('@web/containers/TimeClocks/StaffTimeClocks')));
const TimeClockDetailsPage = lazy(() => loadingChunkRetry(() => import('@web/containers/TimeClocks/TimeClockDetails')));

const Content = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 58px);
  flex-direction: column;
  div[data-component='tabs'] {
    width: 100%;
  }
`;

const StaffTimeClocksIndexLayout = () => {
  const { t } = useLingui();
  const navigate = useNavigate();

  return (
    <DashboardContainer showSecondaryNav>
      <Helmet>
        <title>{t`Staff time clocks | Sage Intelligent Time`}</title>
      </Helmet>

      <PageContent>
        <PageHeader data-testid="time-clocks-title" title={t`Staff time clocks`}>
          <Button
            buttonType={{
              default: 'primary',
              embedded: 'secondary',
            }}
            onClick={() => {
              navigate(newStaffTimeClocksRoute);
            }}
          >
            <Trans>Create time clock</Trans>
          </Button>
        </PageHeader>
        <ErrorBoundary fallbackUI={(error) => <ErrorComponent error={error} />}>
          <Suspense fallback={null}>
            <Content>
              <Outlet />
            </Content>
          </Suspense>
        </ErrorBoundary>
      </PageContent>
    </DashboardContainer>
  );
};

function StaffTimeClocksIndex() {
  const isStaff = useHasStaffTimesheets();

  const enabled = useClockInOutEnabled();

  if (!enabled || !isStaff) {
    return (
      <Routes>
        <Route path="*" element={<NotFoundMessage />} />
      </Routes>
    );
  }
  return (
    <>
      <Routes>
        <Route element={<StaffTimeClocksIndexLayout />}>
          <Route index element={<StaffTimeClocksPage />} />
          <Route path="new" element={<StaffTimeClocksPage activeModal={<CreateTimeClock />} />} />
          <Route path=":timeClockId" element={<StaffTimeClocksPage activeModal={<TimeClockDetailsPage isStaff />} />} />
          <Route
            path=":timeClockId/breaks"
            element={<StaffTimeClocksPage activeModal={<TimeClockDetailsPage isStaff defaultTab="breaks" />} />}
          />
        </Route>
        <Route path="*" element={<NotFoundMessage />} />
      </Routes>
      <TimesheetModalsProvider>
        <TimesheetModals />
      </TimesheetModalsProvider>
    </>
  );
}

export default StaffTimeClocksIndex;
