import { Trans } from '@lingui/react/macro';
import { TimesheetType, useUserDateFormat } from '@sit/client-shared';
import Box from 'carbon-react/lib/components/box';
import { FlatTable, FlatTableBody, FlatTableHead, FlatTableHeader, FlatTableRow } from 'carbon-react/lib/components/flat-table';
import styled from 'styled-components';
import { TimesheetListItem } from './TimesheetListItem';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface TimesheetsListProps {
  timesheets: Pick<TimesheetType, 'startDate' | 'endDate' | 'id' | 'description'>[];
}

export const TimesheetsList = ({ timesheets }: TimesheetsListProps) => {
  const dateFormat = useUserDateFormat();
  return (
    <Root>
      <FlatTable colorTheme="transparent-white">
        <FlatTableHead>
          <FlatTableRow>
            <FlatTableHeader>
              <Box justifyContent="space-between" alignItems="center" display="flex">
                <Trans>Begin date</Trans>
              </Box>
            </FlatTableHeader>
            <FlatTableHeader>
              <Box justifyContent="space-between" alignItems="center" display="flex">
                <Trans>End date</Trans>
              </Box>
            </FlatTableHeader>
            <FlatTableHeader>
              <Box justifyContent="space-between" alignItems="center" display="flex">
                <Trans>Description</Trans>
              </Box>
            </FlatTableHeader>
          </FlatTableRow>
        </FlatTableHead>
        <FlatTableBody>
          {timesheets.map((timesheet) => (
            <TimesheetListItem key={timesheet.id} timesheet={timesheet} dateFormat={dateFormat} />
          ))}
        </FlatTableBody>
      </FlatTable>
    </Root>
  );
};
