import { t } from '@lingui/core/macro';
import { CompanyType } from '@sit/client-shared';
import { getDateFromString, getDayName, getStringFromDate } from '@sit/core';
import { format } from 'date-fns';
import { FIFTEEN_DAYS, ONE_DAY } from '../constants/date';
import { timesheetStartsFirstDayOfMonth, timesheetStartsMidOfMonth } from './date';

class TimesheetStartDayValidator {
  constructor(private company: CompanyType) {}

  /**
   * Get the 0-based day of the week that the company uses as the start of the week
   * @deprecated this should be removed once the company.weekStart is 1-based
   * @returns 0-based day of the week
   */
  momentWeekStart = () => this.company.weekStart - 1;

  validate = (value: string) => this.validateDate(getDateFromString(value));
  validateDate = (value: Date) => {
    const valueString = getStringFromDate(value);
    const { timesheetDuration, weekStart } = this.company;

    if (timesheetDuration === ONE_DAY) return true;
    if (timesheetDuration === FIFTEEN_DAYS) {
      return timesheetStartsFirstDayOfMonth(valueString) || timesheetStartsMidOfMonth(valueString);
    }

    return Number(format(value, 'c')) === weekStart;
  };
  message = () => {
    const { timesheetDuration, weekStart } = this.company;

    if (timesheetDuration === FIFTEEN_DAYS) {
      return t`Invalid start date. Select the 1st or the 16th of the month`;
    }
    return t`Invalid start date. Select a date that is ${getDayName(weekStart - 1)}`;
  };
}

const checkPasswordComplexity = (pwd: string) => {
  if (!pwd || pwd.length < 8) return false;
  const hasUpperCase = /[A-Z]/.test(pwd);
  const hasLowerCase = /[a-z]/.test(pwd);
  const hasNumbers = /\d/.test(pwd);
  const hasNonalphas = /\W/.test(pwd);
  return hasLowerCase && hasNonalphas && hasNumbers && hasUpperCase;
};

// biome-ignore lint/complexity/noStaticOnlyClass: intentional, used in a React component
class PhoneNumberValidator {
  // https://github.com/twilio/authy-form-helpers/blob/master/src/form.authy.js
  static validate = (value: string) => {
    return value !== '' && value.match(/^(\d{3})\W*(\d{3})\W*(\d{0,5})$/);
  };
  static message = () => {
    return t`Invalid phone number`;
  };
}

export { checkPasswordComplexity, PhoneNumberValidator, TimesheetStartDayValidator };
