import { logoutUser } from '@sit/client-shared';
import { useQueryClient } from '@tanstack/react-query';
import { track } from '@web/analytics/analyticsClient';
import trackEvents from '@web/analytics/trackEvents';
import { logout } from '@web/redux/authentication-slice';
import { selectRefreshToken, selectToken } from '@web/redux/selectors';
import { useAppDispatch, useAppSelector } from '@web/redux/store';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useLogout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const token = useAppSelector(selectToken);
  const refreshToken = useAppSelector(selectRefreshToken);
  // biome-ignore lint/correctness/useExhaustiveDependencies: navigate is OK to omit
  return useCallback(async () => {
    dispatch(logout());
    try {
      await logoutUser(token, refreshToken);
    } catch {
      // Do nothing, continue to logout
    }
    navigate('/login');
    track(trackEvents.LOGOUT);
    sessionStorage?.clear();
    queryClient.clear();
  }, [queryClient, token, refreshToken]);
}
