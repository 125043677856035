import { useAsync } from '@sit/client-shared';
import { invalidateIdb } from '../helpers/invalidate-idb';
import { useTokenFragment } from './useTokenFragment';

export function useCacheBustIndexDbIfDifferentUser(): {
  // biome-ignore lint/suspicious/noConfusingVoidType: `void` is used to indicate that the data is not used
  data: void | null;
  loading: boolean;
  error: Error | null;
} {
  const token = useTokenFragment();
  return useAsync(() => invalidateIdb(token), [token]);
}
