import { useLingui } from '@lingui/react/macro';
import { TimeClock, usePostTimeClocks } from '@sit/client-shared';
import { useGetUser } from '@web/api/users/use-get-user';
import type { ClockActions } from '@web/components/TimeClock/types';
import TimeClockModal from '@web/components/TimeClock/ViewTimeClock/TimeClockModal';
import { useCurrentEntity } from '@web/hooks/use-current-entity';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { staffTimeClocksRoute } from './helpers/routes';
import { computeDisableDimensions, computeFallbackTimeclock, getPostTimeClockRequestBody } from './helpers/time-clocks';
import { useClockVisibleFields } from './hooks/useClockVisibleFields';

export const ErrorContainer = styled.div`
  padding-top: ${(props) => props.theme.space[3]};
  flex: 1;
  height: 540px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.space[3]};
`;

function useCreateTimeClockModal() {
  const { t } = useLingui();
  const navigate = useNavigate();
  const companyId = useCurrentEntity()?.id;
  const dimensionFields = useClockVisibleFields();
  const { mutateAsync: createTimeClock } = usePostTimeClocks({ replaceActive: false });
  const { data: user } = useGetUser();

  if (!companyId) {
    throw new Error(t`Company ID not found`);
  }

  if (!user) {
    throw new Error(t`User not found`);
  }

  const draftClock = useMemo(() => {
    const employee = user.employee
      ? { id: user.employee.id, name: `${user.employee.employeeId}, ${user.name}` }
      : /*
          This is a fallback for the case where the user does not have an employee.
          It is handled by the employee selector at the moment of creation, ensuring that the user has an employee.
          This is an architectural issue that should be addressed in the future
         */
        (null as unknown as {
          id: string;
          name: string;
        });
    return computeFallbackTimeclock(companyId, { ...user, employee });
  }, [companyId, user]);
  const defaultDisabledDimensions = useMemo(() => computeDisableDimensions(user), [user]);

  const handleClockAction = async (action: ClockActions, target: TimeClock) => {
    if (action !== 'create') {
      return;
    }

    await createTimeClock({
      body: getPostTimeClockRequestBody(target),
      queryParams: {
        companyId: target.companyId,
      },
    });
    navigate(`${staffTimeClocksRoute}/${target.id}`, { replace: true });
  };

  const handleHideModal = () => {
    const hasHistory: boolean = window.history.state && window.history.state.idx > 0;
    if (hasHistory) {
      window.history.back();
    } else {
      navigate(`${staffTimeClocksRoute}`, { replace: true });
    }
  };

  return {
    draftClock,
    handleHideModal,
    dimensionFields,
    handleClockAction,
    disabledDimensions: defaultDisabledDimensions,
  };
}

function CreateTimeClock() {
  const { t } = useLingui();
  const { draftClock, handleClockAction, handleHideModal, dimensionFields } = useCreateTimeClockModal();

  return (
    <TimeClockModal
      clock={draftClock}
      mode="create"
      isStaff
      onSubmit={async ({ value }) => {
        await handleClockAction('create', value);
      }}
      onAction={handleClockAction}
      title={t`Create time clock`}
      onClose={handleHideModal}
      dimensionFields={dimensionFields}
    />
  );
}

export default CreateTimeClock;
