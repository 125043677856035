import { Notification, NotificationType, PushNotification, useGetNotifications } from '@sit/client-shared';
import { useIsAuthenticated } from '@web/redux/hooks';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useLatestServerNotification } from '../../graphql/subscriptions';
import { NotificationTopic } from './NotificationHandlerService';
import { toastNotification } from './display-notifications';
import { NotificationData } from './notification-data';
import { NotificationHandlerContext } from './NotificationHandlerContext';
import { notificationHanderService } from './notification-handler-service';

function shouldAddNotificationToPanel(notification: PushNotification): boolean {
  switch (notification.topic) {
    case NotificationTopic.SUBMIT_TIMESHEET:
      return true;
    case NotificationTopic.TIMESHEETS:
      return true;
    default:
      return false;
  }
}

const toMeta = (
  notification: Pick<PushNotification, 'data' | 'topic'>,
): {
  meta?: NotificationTopic;
  metaId?: string;
} => {
  switch (notification.topic) {
    case NotificationTopic.SAVE_TIMESHEET:
    case NotificationTopic.SUBMIT_TIMESHEET:
      return {
        meta: notification.topic,
        metaId: notification.data.timesheetId ?? notification.data.metaId,
      };
    case NotificationTopic.TIMESHEETS:
      return {
        meta: notification.topic,
      };
    default:
      return {};
  }
};

const rqToPanelNotification = (notification: Notification): NotificationData => {
  return {
    id: notification.id,
    notification: {
      body: notification.description ?? '',
      title: (notification.message || notification.title) ?? '',
    },
    pushIsRead: notification.pushIsRead,
    timestamp: notification.createdAt,
    type: notification.type as NotificationType,
    ...toMeta({
      topic: notification.meta,
      data: {
        metaId: notification.metaId,
      },
    }),
  };
};

const toPanelNotification = (notification: PushNotification): NotificationData => {
  return {
    id: notification.id,
    notification: notification.notification,
    pushIsRead: false,
    timestamp: notification.timestamp,
    type: notification.type,
    ...toMeta(notification),
  };
};

export const NotificationHandlerProvider = ({ children }: PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const isAuthenticated = useIsAuthenticated();

  const { data: response } = useGetNotifications(
    {},
    {
      enabled: isAuthenticated,
    },
  );

  useEffect(() => {
    if (response) {
      setNotifications(response.map((n) => rqToPanelNotification(n)));
    }
  }, [response]);

  const addNotification = useCallback((notification: PushNotification) => {
    if (shouldAddNotificationToPanel(notification)) {
      setNotifications((n) => [toPanelNotification(notification), ...n]);
    }
  }, []);

  const { data } = useLatestServerNotification();

  useEffect(() => {
    if (data) {
      notificationHanderService.handleNotification(data.newNotification);
      toastNotification(data.newNotification);
      addNotification(data.newNotification);
    }
  }, [data, addNotification]);

  const markAsRead = useCallback(() => {
    setNotifications((n) => n.map((notification) => ({ ...notification, pushIsRead: true })));
  }, []);

  const value = useMemo(() => ({ service: notificationHanderService, notifications, markAsRead }), [notifications, markAsRead]);

  return <NotificationHandlerContext.Provider value={value}>{children}</NotificationHandlerContext.Provider>;
};
