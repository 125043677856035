import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { ReduxStore } from '../types/redux/state';
import { useAppDispatch } from './store';

interface ReplaceTimeClockModalProps {
  currentTimeClockId: string;
  clockInTime: string;
  newTimeClockId: string;
}

interface AttachmentModalProps {
  timesheetId: string;
}

interface ModalProps {
  attachments: AttachmentModalProps;
  replaceTimeClock: ReplaceTimeClockModalProps;
}

type ModalType = keyof ModalProps;

interface ModalState<T extends ModalType> {
  isOpen: boolean;
  modalType: T | null;
  modalProps: ModalProps[T] | null;
}

interface OpenModalPayload<T extends ModalType> {
  modalType: T;
  modalProps: ModalProps[T];
}

const initialState: ModalState<ModalType> = {
  isOpen: false,
  modalType: null,
  modalProps: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<OpenModalPayload<ModalType>>) => {
      state.isOpen = true;
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.modalType = null;
      state.modalProps = null;
    },
  },
});

export const selectIsModalOpen = (state: ReduxStore, modalType: ModalType) => state.modal.modalType === modalType && state.modal.isOpen;
export const selectIsAttachmentsModalOpen = (state: ReduxStore) => selectIsModalOpen(state, 'attachments');
export const selectIsReplaceTimeClockModalOpen = (state: ReduxStore) => selectIsModalOpen(state, 'replaceTimeClock');

export const selectModalProps = (state: ReduxStore) => state.modal.modalProps;
export const selectAttachmentModalProps = selectModalProps as (state: ReduxStore) => AttachmentModalProps;
export const selectReplaceTimeClockModalProps = selectModalProps as (state: ReduxStore) => ReplaceTimeClockModalProps;

export const { openModal, closeModal } = modalSlice.actions;

export const useDispatchCloseModal = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(closeModal()), []);
};

export const useDispatchOpenModal = () => {
  const dispatch = useAppDispatch();
  return useCallback((payload: OpenModalPayload<ModalType>) => {
    dispatch(openModal(payload));
  }, []);
};

export const useModal = () => {
  const close = useDispatchCloseModal();
  const open = useDispatchOpenModal();
  return {
    close,
    open,
  };
};
export default modalSlice.reducer;
