import { TimeClock } from '@sit/client-shared';
import get from 'lodash/get';
import { Paths } from 'type-fest';
import { UpdateClockPayload } from '../../ActiveClock/ClockField';

type PayloadKeys = Exclude<keyof UpdateClockPayload, 'dimensionValues'>;

type FieldsMap = Record<PayloadKeys, Paths<TimeClock>>;

const MAPPED_FIELDS: FieldsMap = {
  billable: 'billable',
  clientId: 'client.id',
  clockInTime: 'clockInTime',
  clockOutTime: 'clockOutTime',
  description: 'description',
  itemId: 'item.id',
  notes: 'notes',
  projectId: 'project.id',
  taskId: 'task.id',
  timeTypeId: 'timeType.id',
  employee: 'employee',
};

export function fieldDiffs(changedClock: TimeClock, originalClock: TimeClock) {
  const changePayload = Object.keys(MAPPED_FIELDS).reduce<UpdateClockPayload>((acc, key) => {
    const field = key as PayloadKeys;
    const clockField = MAPPED_FIELDS[field];

    if (field === 'employee') {
      if (originalClock.employee?.id && originalClock.employee.id !== changedClock.employee.id) {
        acc[field] = changedClock.employee;
      }
    }

    const originalValue = get(originalClock, clockField);
    const changedValue = get(changedClock, clockField);

    if (originalValue !== changedValue) {
      // @ts-expect-error - we are always comparing the same key
      acc[field] = changedValue;
    }

    return acc;
  }, {});

  return changePayload;
}
