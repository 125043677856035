import { useLingui } from '@lingui/react/macro';
import { GetApiItemsResponse, dimensionPageSize, useGetApiItemsInfinite } from '@sit/client-shared';
import { Option } from 'carbon-react/lib/components/select';
import { SelectProps } from './buildSelect';
import { useSelectControls } from './hooks/useSelectControls';
import { Root } from './shared/Root';
import { SelectField } from './shared/SelectField';
import { ValueWrapper } from './shared/ValueWrapper';

export interface ItemSelectProps extends SelectProps<GetApiItemsResponse[number]> {
  timesheetId?: string;
}

export const SelectItem = ({ timesheetId, disabled, onChange, value, label, required, readOnly, ...props }: ItemSelectProps) => {
  const { t } = useLingui();

  const { data, isLoading, fetchNextPage } = useGetApiItemsInfinite({
    queryParams: {
      timesheetId,
      pageSize: `${dimensionPageSize}`,
      pageNumber: '1',
    },
  });

  const { innerValue, options, text, handleChange, loadMore, handleOpen, handleBlur } = useSelectControls(data, value, {
    fetchNextPage,
    onChange,
  });

  const entryValue = !text ? t`Select item` : text;

  return (
    <Root>
      <ValueWrapper width={'200px'} data-pendo-id={'Select.Item'} data-testid={'Select.Item'} disabled={disabled}>
        <SelectField
          label={label}
          required={required}
          onBlur={() => handleBlur()}
          disabled={disabled}
          onChange={handleChange}
          onOpen={handleOpen}
          maxWidth={'100%'}
          value={innerValue}
          isLoading={isLoading}
          readOnly={readOnly}
          // We don't use `readOnly` to allow for the placeholder to be shown
          placeholder={entryValue ?? t`Type to search`}
          enableVirtualScroll
          virtualScrollOverscan={50}
          onListScrollBottom={() => loadMore()}
          error={props.error}
        >
          {options.map((option, index) => (
            <Option key={`Select.Item${option.id ?? index}`} value={option.value} text={option.text} />
          ))}
        </SelectField>
      </ValueWrapper>
    </Root>
  );
};
