import { useFlag } from './useFlags';
import { logout } from '@web/redux/authentication-slice';
import { useAppDispatch } from '@web/redux/store';
import { useEffect } from 'react';
import { getToken, getTokenAud } from '@sit/client-shared';

export function useForceLogoutSITWeb() {
  const dispatch = useAppDispatch();
  const enablePasswordLogin = useFlag('ENABLE_PASSWORD_LOGIN') ?? true;
  const token = getToken();
  const aud = getTokenAud(token);
  useEffect(() => {
    if (aud === 'sit' && !enablePasswordLogin) {
      dispatch(logout());
    }
  }, [enablePasswordLogin, aud]);
}
