import { useLingui } from '@lingui/react/macro';
import { Client } from '@sit/api-types';
import { dimensionPageSize, useGetApiClientsInfinite } from '@sit/client-shared';
import { Option } from 'carbon-react/lib/components/select';
import { SelectProps } from './buildSelect';
import { useSelectControls } from './hooks/useSelectControls';
import { Root } from './shared/Root';
import { SelectField } from './shared/SelectField';
import { ValueWrapper } from './shared/ValueWrapper';

interface SharedProps extends SelectProps<Client> {
  enabled?: boolean;
}

export interface TimesheetClientSelectProps extends SharedProps {
  timesheetId: string;
}
export interface StartTimeClientSelectProps extends SharedProps {
  startDate: string;
}

export type ClientSelectProps = TimesheetClientSelectProps | StartTimeClientSelectProps;

export const SelectClient = ({ disabled, onChange, value, label, required, readOnly, ...props }: ClientSelectProps) => {
  let queryData = {};

  if ('timesheetId' in props) {
    queryData = {
      timesheetId: props.timesheetId,
    };
  } else {
    queryData = {
      startDate: props.startDate,
    };
  }

  const { t } = useLingui();

  const { data, isLoading, fetchNextPage } = useGetApiClientsInfinite({
    queryParams: {
      ...queryData,
      pageSize: `${dimensionPageSize}`,
      pageNumber: '1',
    },
  });

  const { innerValue, options, text, handleChange, loadMore, handleOpen, handleBlur } = useSelectControls(data, value, {
    fetchNextPage,
    onChange,
  });

  const entryValue = !text ? t`Select customer` : text;

  return (
    <Root>
      <ValueWrapper width={props.width} data-pendo-id={'Select.Client'} data-testid={'Select.Client'} disabled={disabled}>
        <SelectField
          label={label}
          required={required}
          onBlur={() => handleBlur()}
          disabled={disabled}
          onChange={handleChange}
          onOpen={handleOpen}
          maxWidth={'100%'}
          value={innerValue}
          isLoading={isLoading}
          readOnly={readOnly}
          // We don't use `readOnly` to allow for the placeholder to be shown
          placeholder={entryValue ?? t`Type to search`}
          enableVirtualScroll
          virtualScrollOverscan={50}
          onListScrollBottom={loadMore}
          error={props.error}
        >
          {options.map((option, index) => (
            <Option key={`Select.Client${option.id ?? index}`} value={option.value} text={option.text} />
          ))}
        </SelectField>
      </ValueWrapper>
    </Root>
  );
};
