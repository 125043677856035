import { Trans } from '@lingui/react/macro';
import Button from '@web/components/Shared/Button';
import Loader from 'carbon-react/lib/components/loader';
import { useState } from 'react';

function DeleteButton({ onAction }: { onAction?: (action: 'delete') => void }) {
  const [isDeleting, setDeleting] = useState(false);

  const handleDelete = () => {
    if (onAction) {
      setDeleting(true);
      onAction?.('delete');
    }
  };

  return (
    <Button
      buttonType={{
        default: 'primary',
        embedded: 'secondary',
      }}
      onClick={() => handleDelete()}
      ml={1}
      destructive
    >
      {isDeleting ? <Loader isInsideButton /> : <Trans>Delete</Trans>}
    </Button>
  );
}

export default DeleteButton;
