import { useLingui } from '@lingui/react/macro';

import { TimeClock } from '@sit/client-shared';
import Button from '@web/components/Shared/Button';
import Tooltip from 'carbon-react/lib/components/tooltip';
import styled from 'styled-components';
import { isClockOnBreak } from '../../helpers/activeClockState';
import { ModalActionKeys } from '../../ViewTimeClock/TimeClockModal';

const StopButton = styled(Button).attrs({ children: null })`
  display: block;
  position: relative;
  padding: 0px;
  width: ${({ theme }) => theme.spacing * 5}px;
  height: ${({ theme }) => theme.spacing * 5}px;

  &::before {
    position: absolute;
    top: ${({ theme }) => theme.spacing * 1.5}px;
    left: ${({ theme }) => theme.spacing * 1.5}px;
    content: '';
    border-radius: 2px;
    width: 12px;
    height: 12px;
    /* background color for primary button */
    background: var(--colorsActionMajor500);
  }

  &:hover:before {
    background: currentColor;
  }
`;

const BreakStartIconButton = styled(Button).attrs({ children: null })`
  display: block;
  position: relative;
  padding: 0px;
  width: ${({ theme }) => theme.spacing * 5}px;
  height: ${({ theme }) => theme.spacing * 5}px;

  &::before {
    position: absolute;
    top: ${({ theme }) => theme.spacing * 1.5}px;
    left: ${({ theme }) => theme.spacing * 1.5}px;
    font-family: CarbonIcons !important;
    content: "\\e96d";
    width: 12px;
    height: 12px;
  }
`;

const BreakEndIconButton = styled(Button).attrs({ children: null })`
  display: block;
  position: relative;
  padding: 0px;
  width: ${({ theme }) => theme.spacing * 5}px;
  height: ${({ theme }) => theme.spacing * 5}px;

  &::before {
    position: absolute;
    top: ${({ theme }) => theme.spacing * 1.5}px;
    left: ${({ theme }) => theme.spacing * 1.5}px;
    font-family: CarbonIcons !important;
    content: "\\e95f";
    width: 12px;
    height: 12px;
  }
`;

function BreakControls({ onAction, clock }: { clock: TimeClock; onAction?: (action: ModalActionKeys) => void }) {
  const { t } = useLingui();
  const onBreak = isClockOnBreak(clock);
  const action = onBreak ? 'break-end' : 'break-start';
  const label = onBreak ? t`End break` : t`Start break`;

  return (
    <Tooltip message={label}>
      {onBreak ? (
        <BreakEndIconButton buttonType="tertiary" onClick={() => onAction?.(action)} size="small" />
      ) : (
        <BreakStartIconButton buttonType="tertiary" onClick={() => onAction?.(action)} size="small" />
      )}
    </Tooltip>
  );
}

function ActiveControls({ clock, onAction }: { clock: TimeClock; onAction?: (action: ModalActionKeys) => void }) {
  const { t } = useLingui();

  return (
    <>
      <BreakControls onAction={onAction} clock={clock} />
      <Tooltip message={t`Clock out`}>
        <StopButton buttonType="tertiary" onClick={() => onAction?.('clock-out')} size="small" />
      </Tooltip>
    </>
  );
}

export default ActiveControls;
