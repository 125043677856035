import { toAppLocale } from '@sit/core';
import { useGetUser } from '@web/api/users/use-get-user';
import { useIsAuthenticated } from '@web/redux/hooks';

const enableLocale = import.meta.env.VITE_APP_ENABLE_LOCALE === 'true';

export function useUserLocale() {
  const isAuthenticated = useIsAuthenticated();
  const userLocale = useGetUser({ enabled: isAuthenticated && enableLocale })?.data?.locale;
  return toAppLocale(userLocale);
}
