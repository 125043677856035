import { useIsStaff } from '../hooks/useIsStaff';
import { useTimesheetModals } from '../hooks/useTimesheetModals';
import { BulkDeleteTimesheets } from './BulkDeleteTimesheetsModal';
import { DuplicateTimesheet } from './DuplicateTimesheet';
import { NewTimesheet } from './NewTimesheet';
import { TimesheetModalType } from './timesheet-modal-type';

export function TimesheetModals() {
  const { state } = useTimesheetModals();
  const staff = useIsStaff();

  return (
    <>
      <NewTimesheet open={state.type === TimesheetModalType.NEW} />
      <DuplicateTimesheet
        staff={staff}
        timesheetId={'timesheetId' in state ? state.timesheetId : undefined}
        open={state.type === TimesheetModalType.DUPLICATE}
      />
      <BulkDeleteTimesheets
        timesheetIds={'timesheetIds' in state ? state.timesheetIds : undefined}
        open={state.type === TimesheetModalType.BULK_DELETE}
      />
    </>
  );
}
