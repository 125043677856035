import { TimeClock } from '@sit/client-shared';
import Dialog from '@web/components/Shared/Dialog';
import React, { useState } from 'react';
import { ClockDimensionsField, toTimeClockFields } from '../ActiveClock/constants';
import TimeClockForm, { TimeClockFormSubmitFn } from '../TimeClockForm';
import { ClockActions } from '../types';

export type ModalActionKeys = Exclude<ClockActions, 'clock-in' | 'view-details'>;
export type ModalContentActionHandler = (action: ModalActionKeys | 'dismiss-modal') => void;
export type ModalContentActionRendererFn = (action: ModalContentActionHandler) => React.ReactNode;

export interface ModalContentProps {
  title: string;
  clock: TimeClock;
  onClose: () => void;
  onSubmit: TimeClockFormSubmitFn;
  onAction: (action: ModalActionKeys, value: TimeClock) => void;
  mode?: 'edit' | 'create';
  isStaff?: boolean;
  dimensionFields?: ClockDimensionsField[];
  tabProps?: {
    onTabChange: (tab: string) => void;
    activeTab: string;
  };
}

const TimeClockModal = ({
  clock,
  mode = 'edit',
  isStaff,
  onClose,
  onSubmit,
  tabProps,
  onAction,
  dimensionFields,
  title,
}: ModalContentProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    const animationTimeout = setTimeout(() => {
      onClose();
      clearTimeout(animationTimeout);
    }, 300);
  };

  const handleButtonAction = (action: ModalActionKeys | 'dismiss-modal', value: TimeClock) => {
    if (action === 'dismiss-modal') {
      handleClose();
      return;
    }

    onAction(action, value);
  };

  return (
    <Dialog open={isOpen} complexForm title={title} onCancel={() => handleClose()}>
      <TimeClockForm
        mode={mode}
        onSubmit={async (clock) => {
          await onSubmit(clock);
        }}
        staffControls={isStaff}
        value={clock}
        fields={dimensionFields ? toTimeClockFields(dimensionFields) : []}
        tabProps={tabProps}
        onAction={handleButtonAction}
      />
    </Dialog>
  );
};

export default TimeClockModal;
