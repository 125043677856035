import { useLingui } from '@lingui/react/macro';
import { CACHE_TIMES, Employee, STALE_TIMES, useGetApiEmployees } from '@sit/client-shared';
import { InfiniteData } from '@tanstack/react-query';
import { Option } from 'carbon-react/lib/components/select';
import { useMemo } from 'react';
import { SelectProps } from './buildSelect';
import { useSelectControls } from './hooks/useSelectControls';
import { Root } from './shared/Root';
import { SelectField } from './shared/SelectField';
import { ValueWrapper } from './shared/ValueWrapper';

export interface EmployeeSelectProps extends Omit<SelectProps<Employee>, 'onChange'> {
  enabled?: boolean;
  companyId?: string;
  firstAsDefault?: boolean;
  onChange: (value: Employee | undefined, quiet?: boolean) => void;
}

export const SelectEmployee = ({
  disabled,
  onChange,
  value,
  label,
  companyId,
  required,
  readOnly,
  firstAsDefault = false,
  ...props
}: EmployeeSelectProps) => {
  const { t } = useLingui();

  const { data, isLoading } = useGetApiEmployees(
    {
      queryParams: {
        companyId,
      },
    },
    { cacheTime: CACHE_TIMES.long, staleTime: STALE_TIMES.long },
  );

  const employees = useMemo<InfiniteData<Employee[]> | undefined>(
    () => (data ? { pages: [data.employees], pageParams: [] } : undefined),
    [data],
  );

  const { innerValue, options, text, handleChange, handleOpen, handleBlur } = useSelectControls(employees, value, {
    onChange,
    useFirstAsDefault: firstAsDefault,
  });

  const entryValue = !text ? t`Select employee` : text;

  return (
    <Root>
      <ValueWrapper width={props.width} data-pendo-id={'Select.Employee'} data-testid={'Select.Employee'} disabled={disabled}>
        <SelectField
          label={label}
          required={required}
          onBlur={handleBlur}
          disabled={disabled}
          onChange={handleChange}
          onOpen={handleOpen}
          maxWidth={'100%'}
          value={innerValue}
          readOnly={readOnly}
          isLoading={isLoading}
          // We don't use `readOnly` to allow for the placeholder to be shown
          placeholder={entryValue ?? t`Type to search`}
          enableVirtualScroll
          virtualScrollOverscan={50}
          error={props.error}
        >
          {options.map((option, index) => (
            <Option key={`Select.Employee${option.id ?? index}`} value={option.value} text={option.text} />
          ))}
        </SelectField>
      </ValueWrapper>
    </Root>
  );
};
