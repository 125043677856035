import { useCallback, useMemo, useState } from 'react';
import { TimesheetModalType } from './timesheet-modal-type';
import { TimesheetModalsContext, TimesheetModalState } from './TimesheetModalContext';

interface TimesheetModalsProviderProps {
  children: React.ReactNode;
}

export const TimesheetModalsProvider = ({ children }: TimesheetModalsProviderProps) => {
  const [modalState, setModalState] = useState<TimesheetModalState>({ type: TimesheetModalType.CLOSED });

  const openNewTimesheetModal = useCallback(() => {
    setModalState({ type: TimesheetModalType.NEW });
  }, []);

  const openDuplicateTimesheetModal = useCallback((timesheetId: string) => {
    setModalState({ type: TimesheetModalType.DUPLICATE, timesheetId });
  }, []);

  const openBulkDeleteTimesheetsModal = useCallback((timesheetIds: string[]) => {
    setModalState({ type: TimesheetModalType.BULK_DELETE, timesheetIds });
  }, []);

  const closeModal = useCallback(() => {
    setModalState({ type: TimesheetModalType.CLOSED });
  }, []);

  const value = useMemo(
    () => ({
      state: modalState,
      openNewTimesheetModal,
      openDuplicateTimesheetModal,
      openBulkDeleteTimesheetsModal,
      closeModal,
    }),
    [modalState],
  );

  return <TimesheetModalsContext.Provider value={value}>{children}</TimesheetModalsContext.Provider>;
};
