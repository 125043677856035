import {
  DimensionValueType,
  DimValueWithObjectName,
  PatchApiTimeClocksIdRequestBody,
  PostApiTimeClocksIdDimensionValueRequestBody,
  TimeClock,
} from '@sit/client-shared';
import { formatDimensionValue } from '@sit/core';
import { useShowIntacctId } from '@web/api/show-intacct-id';
import { SelectCostType } from '@web/components/Fields/Select/CostType';
import { stringWithFallback } from '@web/helpers/label';
import Box from 'carbon-react/lib/components/box';
import { Checkbox } from 'carbon-react/lib/components/checkbox';
import Typography from 'carbon-react/lib/components/typography';
import styled from 'styled-components';
import { Simplify } from 'type-fest';
import { SelectClient, SelectDepartment, SelectItem, SelectLocation, SelectProject, SelectTask, SelectTimeType } from '../../Fields/Select';
import Textarea from '../../Shared/Textarea/DebouncedTextArea';
import { ClockDimensionsField, TimeClockEditableFieldsKeys } from './constants';
import { useFieldLabel } from './hooks/useFieldLabels';

const DescriptionField = styled(Textarea)`
  min-height: ${({ theme }) => theme.spacing * 5 - 2}px !important;
  resize: vertical !important;
  padding: ${({ theme }) => theme.spacing * 1.5 - 2}px ${({ theme }) => theme.spacing * 2}px !important;
`;

// This is an abstraction of the API request body for creating a new time clock
export type UpdateClockPayload = Simplify<
  PatchApiTimeClocksIdRequestBody & {
    employee?: {
      id: string;
      name: string | null;
    };
  }
>;

export type OnDimensionValueChangeFn = (payload: PostApiTimeClocksIdDimensionValueRequestBody) => void;
export type OnFieldChangeFn = (payload: UpdateClockPayload, quiet?: boolean) => Promise<void> | void;

type FieldKeys = keyof UpdateClockPayload;

export interface ClockFieldProps {
  clock: TimeClock;
  startDate: string;
  objectName: string;
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  type: ClockDimensionsField;
  debounceDisabled?: boolean;
  onFieldChange?: OnFieldChangeFn;
  dimensionFields: ClockDimensionsField[];
  onDimensionValueChange?: OnDimensionValueChangeFn;
  error?: string;
}

const computeUddName = (dimensionObjectName: string) => {
  switch (dimensionObjectName) {
    case 'DEPARTMENT':
      return 'department';
    case 'LOCATION':
      return 'location';
    case 'COSTTYPE':
      return 'costType';
    default:
      return dimensionObjectName;
  }
};

function computedUddIds(clock: TimeClock): Record<string, DimValueWithObjectName> {
  const { dimensionsValues = [] } = clock;

  const dimensions: Record<string, DimValueWithObjectName> = {};

  for (const dimension of dimensionsValues) {
    dimensions[computeUddName(dimension.dimensionObjectName)] = dimension;
  }

  return dimensions;
}

function getFieldsManager(dimensionObjectName: string, onDimensionChange: OnDimensionValueChangeFn, onFieldChange: OnFieldChangeFn) {
  function updateDimensionValue(value: DimensionValueType | undefined) {
    if (!value) {
      return;
    }

    onDimensionChange({ valueId: value.id, dimensionObjectName: dimensionObjectName });
  }

  function updateFieldValue(field: FieldKeys, valueId: string | undefined) {
    if (field !== 'description' && field !== 'notes' && !valueId) {
      return;
    }

    onFieldChange({ [field]: valueId });
  }

  const updateBillable = (value: boolean) => {
    onFieldChange({ billable: value });
  };

  return { updateField: updateFieldValue, updateBillable, updateDimensionValue };
}

interface ReadOnlyClockFieldProps {
  clock: TimeClock;
  type: ClockDimensionsField;
}

function computeFieldValue(clock: TimeClock, type: ClockDimensionsField, showIntacctId: boolean) {
  if (['billable', 'description', 'notes'].includes(type)) {
    return clock[type as keyof TimeClock];
  }

  if (['location', 'department'].includes(type)) {
    const dimensionData = computedUddIds(clock)[type];

    return dimensionData === undefined
      ? stringWithFallback()
      : formatDimensionValue(showIntacctId, dimensionData.externalId, dimensionData.name);
  }

  const fieldData = clock[type as TimeClockEditableFieldsKeys];
  const hideIntacctId = !fieldData || !('externalId' in fieldData) || !showIntacctId || fieldData.externalId === null;

  return hideIntacctId ? stringWithFallback(fieldData?.name) : formatDimensionValue(showIntacctId, fieldData.externalId, fieldData.name);
}

const ReadOnlyClockField = ({ clock, type }: ReadOnlyClockFieldProps) => {
  const showIntacctId = useShowIntacctId();
  const label = useFieldLabel(type);
  const value = computeFieldValue(clock, type, showIntacctId);

  return (
    <Box>
      <Typography variant="strong">{label}</Typography>
      <Typography truncate>{value}</Typography>
    </Box>
  );
};

const disableableFields = new Set(['location', 'department', 'billable', 'description', 'notes']);

const isDisableByRequired = (
  clock: TimeClock,
  dimensionFields: ClockDimensionsField[],
  type: ClockDimensionsField | ClockDimensionsField[],
) => {
  const types = Array.isArray(type) ? type : [type];

  return types.some((type) => {
    if (disableableFields.has(type)) {
      return false;
    }

    return dimensionFields.includes(type) && !clock[type as TimeClockEditableFieldsKeys];
  });
};

const ClockField = ({
  clock,
  type,
  objectName,
  startDate,
  error,
  dimensionFields,
  disabled = false,
  required = false,
  readonly = false,
  debounceDisabled = false,
  onFieldChange = () => Promise.resolve(),
  onDimensionValueChange = () => undefined,
}: ClockFieldProps) => {
  const label = useFieldLabel(type);
  const uddIds = computedUddIds(clock);

  const { updateField, updateDimensionValue, updateBillable } = getFieldsManager(objectName, onDimensionValueChange, onFieldChange);

  if (readonly) {
    return <ReadOnlyClockField clock={clock} type={type} />;
  }

  switch (type) {
    case 'client':
      return (
        <SelectClient
          label={label}
          required={required}
          disabled={disabled}
          startDate={startDate}
          onChange={(client) => updateField('clientId', client?.id)}
          value={clock.client?.id}
          width="100%"
          error={!disabled ? error : undefined}
        />
      );
    case 'project': {
      const projectDisabled = disabled || isDisableByRequired(clock, dimensionFields, 'client');
      return (
        <SelectProject
          label={label}
          required={required}
          disabled={projectDisabled}
          clientId={clock.client?.id}
          onChange={(project) => updateField('projectId', project?.id)}
          value={clock.project?.id}
          width="100%"
          error={!projectDisabled ? error : undefined}
        />
      );
    }
    case 'task': {
      const taskDisabled = disabled || isDisableByRequired(clock, dimensionFields, 'project');
      return (
        <SelectTask
          label={label}
          required={required}
          disabled={taskDisabled}
          projectId={clock.project?.id}
          value={clock.task?.id}
          onChange={(task) => updateField('taskId', task?.id)}
          width="100%"
          error={!taskDisabled ? error : undefined}
        />
      );
    }
    case 'item':
      return (
        <SelectItem
          label={label}
          required={required}
          disabled={disabled}
          value={clock.item?.id}
          onChange={(item) => updateField('itemId', item?.id)}
          width="100%"
          error={!disabled ? error : undefined}
        />
      );
    case 'location':
      return (
        <SelectLocation
          label={label}
          required={required}
          disabled={disabled}
          projectId={clock.project?.id}
          timesheetId={clock.timesheetId}
          companyId={clock.companyId}
          onChange={updateDimensionValue}
          value={uddIds.location?.id}
          width="100%"
          error={!disabled ? error : undefined}
        />
      );
    case 'timeType':
      return (
        <SelectTimeType
          label={label}
          required={required}
          disabled={disabled}
          onChange={(timeType) => updateField('timeTypeId', timeType?.id)}
          value={clock.timeType?.id}
          width="100%"
          companyId={clock.companyId}
          timesheetId={clock.timesheetId ?? undefined}
          error={!disabled ? error : undefined}
        />
      );
    case 'department':
      return (
        <SelectDepartment
          label={label}
          required={required}
          disabled={disabled}
          projectId={clock.project?.id}
          onChange={updateDimensionValue}
          timesheetId={clock.timesheetId}
          companyId={clock.companyId}
          value={uddIds.department?.id}
          width="100%"
          error={!disabled ? error : undefined}
        />
      );
    case 'costType':
      return (
        <SelectCostType
          label={label}
          required={required}
          disabled={disabled}
          projectId={clock.project?.id}
          taskId={clock.task?.id}
          onChange={updateDimensionValue}
          timesheetId={clock.timesheetId ?? undefined}
          value={uddIds.costType?.id}
          width="100%"
          error={!disabled ? error : undefined}
        />
      );
    case 'billable':
      return (
        <Box pt={3} height={64}>
          <Box height={40} display={'flex'} alignItems={'center'}>
            <Checkbox
              label={label}
              required={required}
              disabled={disabled}
              error={!disabled ? error : undefined}
              checked={clock.billable}
              onChange={(e) => updateBillable(e.target.checked)}
            />
          </Box>
        </Box>
      );
    case 'description':
      return (
        <Box>
          <DescriptionField
            label={label}
            required={required}
            disabled={disabled}
            onValueChangeDebounced={(value) => updateField('description', value)}
            defaultValue={clock.description ?? ''}
            width={'100%'}
            rows={1}
            minLength={1}
            debounceDelay={debounceDisabled ? 0 : 500}
            error={!disabled ? error : undefined}
          />
        </Box>
      );
    case 'notes':
      return (
        <Box>
          <DescriptionField
            label={label}
            required={required}
            disabled={disabled}
            onValueChangeDebounced={(value) => updateField('notes', value)}
            defaultValue={clock.notes ?? ''}
            width={'100%'}
            rows={1}
            minLength={1}
            debounceDelay={debounceDisabled ? 0 : 500}
            error={!disabled ? error : undefined}
          />
        </Box>
      );
    default:
      return null;
  }
};

export default ClockField;
