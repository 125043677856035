import { createContext } from 'react';
import { NotificationData } from './notification-data';
import NotificationHandlerService from './NotificationHandlerService';
import { notificationHanderService } from './notification-handler-service';

interface NotificationContext {
  notifications: NotificationData[];
  service: NotificationHandlerService;
  markAsRead: () => void;
}

export const NotificationHandlerContext = createContext<NotificationContext>({
  service: notificationHanderService,
  notifications: [],
  markAsRead: () => {},
});
