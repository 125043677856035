import { formatIntacctDate, TimesheetType } from '@sit/client-shared';
import { getDateFromString } from '@sit/core';
import { stringWithFallback } from '@web/helpers/label';
import { useTheme } from '@web/styles/theme/hooks';
import { FlatTableCell, FlatTableRow } from 'carbon-react/lib/components/flat-table';
import Typography from 'carbon-react/lib/components/typography';

interface TimesheetListItemProps {
  timesheet: Pick<TimesheetType, 'startDate' | 'endDate' | 'id' | 'description'>;
  dateFormat: string;
}

export const TimesheetListItem = ({ timesheet, dateFormat }: TimesheetListItemProps) => {
  const { startDate: startDateString, endDate: endDateString, id, description } = timesheet;
  const theme = useTheme();

  const startDate = getDateFromString(startDateString);
  const endDate = getDateFromString(endDateString);

  return (
    <FlatTableRow key={id}>
      <FlatTableCell width={theme.spacing * 15}>
        <Typography mb={0}>{formatIntacctDate(startDate, dateFormat)}</Typography>
      </FlatTableCell>
      <FlatTableCell width={theme.spacing * 15}>
        <Typography mb={0}>{formatIntacctDate(endDate, dateFormat)}</Typography>
      </FlatTableCell>
      <FlatTableCell>
        <Typography mb={0}>{stringWithFallback(description)}</Typography>
      </FlatTableCell>
    </FlatTableRow>
  );
};
